import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Servicio, ServiciosService } from 'fullpits-commons';

@Component({
  selector: 'app-seleccionar-servicio-unico',
  templateUrl: './seleccionar-servicio-unico.component.html',
  styleUrls: ['./seleccionar-servicio-unico.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeleccionarServicioUnicoComponent implements OnInit, OnDestroy {
  servicios: Servicio[] = [];
  columns: string[] = ['select'];
  dataSource: MatTableDataSource<Servicio> = new MatTableDataSource<Servicio>();
  selection = new SelectionModel<Servicio>(true, []);
  categoryLavadoYDetailing = 13;
  POLARIZADO = 61;
  MECANICA_GENERAL = 16;
  OTROS_SOLO_ADMIN = 89;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: number,
    private dialogRef: MatDialogRef<SeleccionarServicioUnicoComponent>,
    private serviciosService: ServiciosService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    if (this.selection.selected.length > 0) {
      this.servicios = this.selection.selected;
      this.dialogRef.close(this.servicios);
    }
  }

  ngOnInit(): void {
    this.traerServiciosTaller();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: Servicio) {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id! + 1
    }`;
  }

  traerServiciosTaller() {
    this.serviciosService
      .getPaginatedByTypeServiceId(
        `${this.categoryLavadoYDetailing},${this.POLARIZADO},${this.OTROS_SOLO_ADMIN},${this.MECANICA_GENERAL}`,
        0,
        0
      )
      .subscribe((resp) => {
        this.dataSource = new MatTableDataSource<Servicio>(resp);
        this.cd.markForCheck();
      });
  }

  datosSeleccionados() {
    this.servicios = this.selection.selected;
    this.dialogRef.close(this.servicios);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}
